import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Slide } from "react-slideshow-image";

import defaultData from "../data.json";
import {
	getSubCategoriesByCategory,
	getProductsByCategory,
	getProductsBySubcategory,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import TagFilter from "./filters/tagFilter";
import Breadcrumbs from "./breadcrumbs";
import Products from "./products";
import Pagination from "./pagination";

import translations from "../translations.json";

const slideImages = [
	{
		url: "slides/1.jpg",
		caption: "",
	},
	{
		url: "slides/2.jpg",
		caption: "",
	},
	{
		url: "slides/3.jpg",
		caption: "",
	},
	{
		url: "slides/4.jpg",
		caption: "",
	},
	{
		url: "slides/5.jpg",
		caption: "",
	},
	{
		url: "slides/6.jpg",
		caption: "",
	},
	{
		url: "slides/7.jpg",
		caption: "",
	},
	{
		url: "slides/10bis.jpg",
		caption: "",
	},

	{
		url: "slides/12.jpg",
		caption: "",
	},
	{
		url: "slides/13.jpg",
		caption: "",
	},
	{
		url: "slides/14.jpg",
		caption: "",
	},
	{
		url: "slides/15.jpg",
		caption: "",
	},
	{
		url: "slides/16.jpg",
		caption: "",
	},
	{
		url: "slides/22.jpg",
		caption: "",
	},
];

const Home = () => {
	const [language, setLanguage]: any = useState("en");
	const history = useHistory();
	const { idActiveFilter, idActiveTag }: any = useParams();
	

	const [filters, setFilters]: any = useState([
		{
			id: 1,
			label:
				translations["cat1Frontend"][
					language as keyof typeof translations["cat1Frontend"]
				],
			image: "shoes/flat_shoes.png",
		},
		{
			id: 2,
			label:
				translations["cat2Frontend"][
					language as keyof typeof translations["cat2Frontend"]
				],
			image: "shoes/high_heel.png",
		},
		{
			id: 3,
			label:
				translations["cat3Frontend"][
					language as keyof typeof translations["cat3Frontend"]
				],
			image: "shoes/sandals.png",
		},
		{
			id: 4,
			label:
				translations["cat4Frontend"][
					language as keyof typeof translations["cat4Frontend"]
				],
			image: "shoes/boots.png",
		},
		{
			id: 5,
			label:
				translations["cat5Frontend"][
					language as keyof typeof translations["cat5Frontend"]
				],
			image: "shoes/booties.png",
		},
		{
			id: 6,
			label:
				translations["cat6Frontend"][
					language as keyof typeof translations["cat6Frontend"]
				],
			image: "shoes/sneakers.png",
		},
	]);

	const [products, setProducts] = useState();
	const [activeFilter, setActiveFilter] = useState(
		idActiveFilter ? idActiveFilter : 0
	);
	const [tags, setTags] = useState([{ id: 0, label: "", label_it: "" }]);
	const [activeTag, setActiveTag] = useState(
		idActiveTag ? parseInt(idActiveTag) : 0
	);
	const [crumbs, setCrumbs] = useState([{ name: "", path: "" }]);

	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState({
		path: "productCode",
		order: "ASC",
	});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		let filtersApp = [
			{
				id: 1,
				label:
					translations["cat1Frontend"][
						language as keyof typeof translations["cat1Frontend"]
					],
				image: "shoes/flat_shoes.png",
			},
			{
				id: 2,
				label:
					translations["cat2Frontend"][
						language as keyof typeof translations["cat2Frontend"]
					],
				image: "shoes/high_heel.png",
			},
			{
				id: 3,
				label:
					translations["cat3Frontend"][
						language as keyof typeof translations["cat3Frontend"]
					],
				image: "shoes/sandals.png",
			},
			{
				id: 4,
				label:
					translations["cat4Frontend"][
						language as keyof typeof translations["cat4Frontend"]
					],
				image: "shoes/boots.png",
			},
			{
				id: 5,
				label:
					translations["cat5Frontend"][
						language as keyof typeof translations["cat5Frontend"]
					],
				image: "shoes/booties.png",
			},
			{
				id: 6,
				label:
					translations["cat6Frontend"][
						language as keyof typeof translations["cat6Frontend"]
					],
				image: "shoes/sneakers.png",
			},
		];
		setFilters(filtersApp);

		////console.log(language);

		if (activeFilter != 0) {
			getSubCategoriesByCategory({ categoryId: activeFilter }).then(
				(res: any) => {
					//console.log(language);
					setTags(res[0]);
					if (activeTag != 0) {
						const breadcrumbs: any = [{ name: "HOME", path: "/" }];
						const filterCrumb = {
							name: filtersApp
								.find((x: any) => x.id == activeFilter)!
								.label.toUpperCase(),
							path: "/home/" + activeFilter,
						};
						breadcrumbs.push(filterCrumb);
						let tagCrumb = {};
						if (language == "en")
							tagCrumb = {
								name: res[0]
									.find((x: any) => x.id == activeTag)!
									.label.toUpperCase(),
								path: "",
							};
						else
							tagCrumb = {
								name: res[0]
									.find((x: any) => x.id == activeTag)!
									.label_it.toUpperCase(),
								path: "",
							};
						breadcrumbs.push(tagCrumb);
						setCrumbs(breadcrumbs);
					}
				}
			);

			const breadcrumbs: any = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filtersApp
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "/home/" + activeFilter,
			};
			breadcrumbs.push(filterCrumb);
			setCrumbs(breadcrumbs);
		}
	}, [language]);

	useEffect(() => {
		if (activeFilter !== 0) {
			getSubCategoriesByCategory({ categoryId: activeFilter }).then(
				(res: any) => {
					setTags(res[0]);
					if (activeTag !== 0) {
						const breadcrumbs: any = [{ name: "HOME", path: "/" }];
						const filterCrumb = {
							name: filters
								.find((x: any) => x.id == activeFilter)!
								.label.toUpperCase(),
							path: "/home/" + activeFilter,
						};
						breadcrumbs.push(filterCrumb);
						let tagCrumb = {};
						if (language == "en")
							tagCrumb = {
								name: res[0]
									.find((x: any) => x.id == activeTag)!
									.label.toUpperCase(),
								path: "",
							};
						else
							tagCrumb = {
								name: res[0]
									.find((x: any) => x.id == activeTag)!
									.label_it.toUpperCase(),
								path: "",
							};
						breadcrumbs.push(tagCrumb);
						setCrumbs(breadcrumbs);
					}
				}
			);

			if (activeTag === 0) {
				getProductsByCategory({
					sortBy: sortColumn["path"],
					sortDirection: sortColumn["order"],
					perPage: pageSize,
					page: currentPage - 1,
					categoryId: activeFilter,
				}).then((res: any) => {
					setProducts(res[0]);
					setTotal(res[1]);
				});
			}

			const breadcrumbs: any = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filters
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "/home/" + activeFilter,
			};
			breadcrumbs.push(filterCrumb);
			setCrumbs(breadcrumbs);
			if (idActiveTag === 0) setActiveTag(0);
		}
	}, [activeFilter]);

	useEffect(() => {
		if (activeTag !== 0) {
			const breadcrumbs: any = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filters
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "/home/" + activeFilter,
			};
			breadcrumbs.push(filterCrumb);

			if (tags.find((x: any) => x.id == activeTag)) {
				let tagCrumb = {};
				if (language == "en")
					tagCrumb = {
						name: tags.find((x: any) => x.id == activeTag)!.label.toUpperCase(),
						path: "",
					};
				else
					tagCrumb = {
						name: tags
							.find((x: any) => x.id == activeTag)!
							.label_it.toUpperCase(),
						path: "",
					};
				breadcrumbs.push(tagCrumb);
				setCrumbs(breadcrumbs);
			}

			getProductsBySubcategory({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: 0,
				subcategoryId: activeTag,
			}).then((res: any) => {
				setCurrentPage(1);
				setProducts(res[0]);
				setTotal(res[1]);
			});
		} else if (activeFilter !== 0) {
			const breadcrumbs = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filters
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "/home/" + activeFilter,
			};
			breadcrumbs.push(filterCrumb);
			setCrumbs(breadcrumbs);
		}
	}, [activeTag]);

	const handlePageChange = (page: any) => {
		setCurrentPage(page);
		if (activeTag === 0) {
			getProductsByCategory({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: page - 1,
				categoryId: activeFilter,
			}).then((res: any) => {
				setProducts(res[0]);
				setTotal(res[1]);
			});
		} else {
			getProductsBySubcategory({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: page - 1,
				subcategoryId: activeTag,
			}).then((res: any) => {
				setProducts(res[0]);
				setTotal(res[1]);
			});
		}
	};

	const updateProducts = () => {};

	const handleActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
		setActiveFilter(activeFilter);
		setCurrentPage(1);
	};

	const handleActiveTag = (activeTag: any) => {
		history.push("/home/" + activeFilter + "/" + activeTag);
		setActiveTag(activeTag);
		setCurrentPage(1);
	};

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = currentPage * pageSize;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={handleActiveFilter}
							activeFilter={activeFilter}
						/>
						{activeFilter !== 0 && (
							<TagFilter
								setActiveTag={handleActiveTag}
								activeTag={activeTag}
								tags={tags}
							/>
						)}
					</Col>
				</Row>
				{activeFilter === 0 && (
					<Row>
						{/* <Col sm={6} className="home-column">
							<img
								src={process.env.PUBLIC_URL + "/imgs/home1.png"}
								className="home-image"
							/>
						</Col>
						<Col sm={6} className="home-column">
							<img
								src={process.env.PUBLIC_URL + "/imgs/home2.png"}
								className="home-image"
							/>
				</Col> */}
						<Col sm={12} style={{ marginBottom: 50 }}>
							<div className="slide-container" style={{ height: "750px" }}>
								<Slide>
									{slideImages.map((slideImage, index) => (
										<div
											className="each-slide"
											key={index}
											style={{ height: "750px" }}
										>
											<div
												style={{
													backgroundImage: `url(${slideImage.url})`,
													height: "750px",
												}}
											>
												<span>{slideImage.caption}</span>
											</div>
										</div>
									))}
								</Slide>
							</div>
						</Col>
					</Row>
				)}

				{activeFilter !== 0 && (
					<Row className="breacrumbs-row">
						<Col sm={10} style={{ paddingLeft: 0 }}>
							<Breadcrumbs crumbs={crumbs} />
						</Col>
					</Row>
				)}

				{total !== 0 && <Products products={products} />}

				{activeFilter !== 0 && (
					<Row className="products-table-infos">
						<Col sm={4} style={{ verticalAlign: "middle" }}>
							<span className="text-small">
								<b>{tableInfo1}</b> to <b>{tableInfo2}</b> of <b>{total}</b>
							</span>
						</Col>
						<Col sm={8} style={{ textAlign: "right" }}>
							<Pagination
								itemsCount={total}
								pageSize={pageSize}
								currentPage={currentPage}
								onPageChange={handlePageChange}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</div>
	);
};

export default Home;
