import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";

const labels = [
	{
		label: "productCode",
		type: "Product Code",
	},
	{
		label: "modelName",
		type: "Model Name",
	},
	{
		label: "heelHeightId",
		type: "Heel Height",
	},
	{
		label: "brandId",
		type: "Brand",
	},
	{
		label: "year",
		type: "Year",
	},
	{
		label: "seasonId",
		type: "Season",
	},
	{
		label: "productBrandCode",
		type: "Brand Code",
	},
	{
		label: "categoryId",
		type: "Category",
	},
	{
		label: "subcategoryId",
		type: "Sub Category",
	},
	{
		label: "modelTypeId",
		type: "Model",
	},
	{
		label: "materialId",
		type: "Materials",
	},
	{
		label: "colorId",
		type: "Colors",
	},
	{
		label: "inStocks",
		type: "In Archives",
	},
	{
		label: "materialProcessing",
		type: "Processing",
	},
	{
		label: "variants",
		type: "Variants",
	},
	{
		label: "specificModelId",
		type: "Model Details",
	},
	{
		label: "decorativeThemes",
		type: "Decorative Motif",
	},
	{
		label: "materialProcessings",
		type: "Processing",
	},
];

const ignoreClickTags = ["modelTypeId", "modelName"];

const ProductTag = ({ type, tags, print = false }: any) => {
	const history = useHistory();

	useEffect(() => {
		////console.log(tags);
		////console.log("Home rendered successfully");
		setLanguage(localStorage.getItem("language"));
	});

	const [language, setLanguage]: any = useState("en");

	const handleClick = (tag: any) => {
		if (!tag.id) return;
		if (ignoreClickTags.includes(tag.type)) return;

		if (tag.type === "variants") {
			history.push("/product/" + tag.id);
			return;
		}

		window.open("/filters?" + tag.type + "=" + tag.id, "_self");
	};

	if (!tags) return null;

	let classes = "product-tag-container";
	if (print) classes += " print";

	return (
		<Col sm={12} className={classes}>
			<Row>
				<Col sm={3}>
					<span className="product-tag-type">
						{labels.find((o: any) => o.label === type)
							? labels.find((o: any) => o.label === type)?.type
							: type}
					</span>
				</Col>
				<Col sm={9} className="product-tags">
					{tags.map((tag: any, index: number) => {
						return (
							<span
								className="product-tag"
								key={index}
								onClick={() => handleClick(tag)}
							>
								<span className="text-small">
									{language == "en" || !tag.label_it ? tag.label : tag.label_it}
								</span>
							</span>
						);
					})}
				</Col>
			</Row>
		</Col>
	);
};

export default ProductTag;
